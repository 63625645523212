import React, { useEffect } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { PageContent } from './ProfilePage.styles';
import ReactPhoneInput from 'react-phone-input-material-ui';

const socialFields: any[] = [
  // {
  //   name: 'email',
  //   label: 'Adres e-mail',
  //   icon: <AlternateEmailIcon />,
  // },
  {
    name: 'website',
    label: 'Strona internetowa',
    icon: <LanguageIcon />,
  },
  {
    name: 'facebook',
    label: 'Facebook (pełny adres)',
    icon: <FacebookIcon />,
  },
  {
    name: 'instagram',
    label: 'Instagram (tylko nazwa użytkownika)',
    icon: <InstagramIcon />,
  },
];

const ProfileContactData = () => {
  const navigate = useNavigate();
  const [trainerData, updateTrainerData, formErrors] = useOutletContext<any>();
  const { register, handleSubmit, reset, control } = useForm();

  useEffect(() => {
    if (trainerData) {
      reset({
        phone: trainerData.phone,
        email: trainerData.email,
        website: trainerData.website,
        facebook: trainerData.facebook,
        instagram: trainerData.instagram,
      });
    }
  }, [trainerData]);

  const onSubmit = (data: object) => {
    updateTrainerData({ ...data });
  };

  return (
    <>
      <PageHeader
        label={'Social media i kontakt'}
        onBackButtonClick={() => navigate('/profile')}
      />
      <PageContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={8} sm={12}>
              <FormControl sx={{ mb: 2, width: '100%' }}>
                <FormLabel>Numer telefonu</FormLabel>
                <Controller
                  render={({ field }) => (
                    <ReactPhoneInput
                      {...field}
                      country={'pl'}
                      enableAreaCodes={false}
                      countryCodeEditable={false}
                      onlyCountries={['pl']}
                      label=""
                      component={TextField}
                      inputProps={{
                        variant: 'outlined',
                        size: 'small',
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position={'start'}>
                              <LocalPhoneIcon />
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  )}
                  name={'phone'}
                  control={control}
                />
                {formErrors.phone && (
                  <FormHelperText error={true}>
                    {formErrors.phone}
                  </FormHelperText>
                )}
              </FormControl>

              {socialFields.length > 0 &&
                socialFields.map((item, key) => {
                  return (
                    <FormControl sx={{ mb: 2, width: '100%' }} key={key}>
                      <FormLabel>{item.label}</FormLabel>
                      <TextField
                        {...register(item.name)}
                        variant={'outlined'}
                        size={'small'}
                        autoComplete={'off'}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position={'start'}>
                              {item.icon}
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formErrors.trainingLocation && (
                        <FormHelperText error={true}>
                          {formErrors[item.name]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                })}

              <FormControl>
                <Button variant={'contained'} type={'submit'}>
                  Zapisz zmiany
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};
export default ProfileContactData;
