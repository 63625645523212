import React from 'react';
import { Box, Drawer, Toolbar } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NavItem from './NavItem';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
// import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { useAuth } from '../../providers/auth/auth.context';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PagesIcon from '@mui/icons-material/Pages';
import MessageIcon from '@mui/icons-material/Message';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const drawerWidth = 250;
export const menuItems = [
  // { label: 'Strona główna', path: '/', icon: <HomeIcon /> },
  { label: 'Wizytówka', path: '/profile', icon: <AccountBoxIcon /> },
  // { label: 'Kalendarz', path: '/workouts', icon: <CalendarMonthIcon /> },
  { label: 'Dostępność', path: '/availability', icon: <EventAvailableIcon /> },
  { label: 'Usługi', path: '/services', icon: <AttachMoneyIcon /> },
  { label: 'Pakiety', path: '/packages', icon: <PagesIcon /> },
  {
    label: 'Miejsca treningów',
    path: '/event-locations',
    icon: <LocationOnIcon />,
  },
  { label: 'Klienci', path: '/clients', icon: <PeopleIcon /> },
  { label: 'Wiadomości', path: '/messages', icon: <MessageIcon /> },
  { label: 'Logowanie', path: '/login', icon: <VpnKeyIcon />, notAuth: true },
  {
    label: 'Rejestracja',
    path: '/register',
    icon: <HowToRegIcon />,
    notAuth: true,
  },
  // { label: 'Regulaminy', path: '/terms', icon: <GavelIcon />, open: true },
  {
    label: 'Pomoc i kontakt',
    path: '/contact',
    icon: <ContactSupportIcon />,
    open: true,
  },
];
const getMenuItems = (isAuth: boolean = false) => {
  if (isAuth) {
    return menuItems.filter((item) => !item.notAuth || item.open);
  } else {
    return menuItems.filter((item) => item.notAuth || item.open);
  }
};

const Navigation = ({
  isOpen,
  handleNavigationToggle,
}: {
  isOpen: boolean;
  handleNavigationToggle: () => void;
}) => {
  const { token, user, onLogout } = useAuth();

  return (
    <Box
      component={'nav'}
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      <Drawer
        variant={'temporary'}
        open={isOpen}
        onClose={handleNavigationToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.d
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <Toolbar />
        <Divider />
        <List>
          {getMenuItems(!!token).map((item, key) => {
            return (
              <NavItem
                key={key}
                label={item.label}
                path={item.path}
                icon={item.icon}
              />
            );
          })}
          {token && user && (
            <NavItem
              label={'Wyloguj się'}
              path={''}
              icon={<LogoutIcon />}
              onClick={onLogout}
            />
          )}
        </List>
      </Drawer>

      <Drawer
        variant={'permanent'}
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        <Toolbar />
        <Divider />
        <List>
          {getMenuItems(!!token).map((item, key) => (
            <NavItem
              key={key}
              label={item.label}
              path={item.path}
              icon={item.icon}
            />
          ))}
          {token && user && (
            <NavItem
              label={'Wyloguj się'}
              path={''}
              icon={<LogoutIcon />}
              onClick={onLogout}
            />
          )}
        </List>
      </Drawer>
    </Box>
  );
};
export default Navigation;
